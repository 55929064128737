<template>
  <main-document
    :document="document"
    :documentHeaderComponentName="'account-header'"
    :tabs="tabs"
  />
  <!-- <span>{{document}}</span> -->
</template>

<script>
export default {
  components: {
    MainDocument: () => import('@/components/main-document')
  },
  data () {
    return {
      document: null,
      tabs: [
        {
          documentType: 'account-infos',
          title: this.$t('t.Infos'),
          icon: '',
          isLoaded: false,
          isNew: false,
          isPristine: true,
          canSave: true,
          canUpdate: true,
          canClose: false,
          cancel: undefined,
          showComment: false
        },
        {
          documentType: 'account-summary',
          title: this.$t('t.Summary')
        },
        {
          documentType: 'documents',
          title: this.$t('t.Documents')
        },
        {
          documentType: 'hierarchy',
          title: this.$t('t.Hierarchy')
        },
        {
          documentType: 'to-do',
          title: this.$t('t.ToDo'),
          icon: '',
          isLoaded: false,
          isNew: false,
          isPristine: true,
          canSave: true,
          canUpdate: true,
          canClose: false,
          cancel: undefined,
          showComment: false
        }
      ]
    }
  },
  mounted () {
    this.query()
  },
  methods: {
    async query () {
      const id = '8fa4e246-95a0-e811-9cd7-dc5360f6357a' // this.$route.params.id
      const e = await this.$http().get('/core/v6/accounts/' + id)
      this.document = e.data
    }
  }
}
</script>

<style lang="stylus" scoped></style>
